import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { ModalTypeEnum } from "../../utils";
import { useModals } from "../../contexts/modals";
import { ProgrammationTypeForm, RituelParametersForm } from "../../components";

function ProfilParameters() {
  const { openModal } = useModals();

  return (
    <div>
      <RituelParametersForm />
      <hr className="border-3" />
      <div className="text-center">
        <button className="btn btn-primary mb-3" onClick={() => openModal(ModalTypeEnum.Programmation)}>
          <FontAwesomeIcon icon={faCalendarDays} /> Programmation
        </button>
        <ProgrammationTypeForm />
      </div>
    </div>
  );
}

export default ProfilParameters;
