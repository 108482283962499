import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../hooks";
import { toast } from "react-toastify";
import { Routes } from "../routes";
import { ProgrammationTypeEnum, SchoolZoneEnum, UserTypeEnum } from "../utils";
import { Programmation } from "../types";

type ContextData = {
  user: UserData | undefined | null;
  logout: () => void;
  setRituelParameters: (schoolZone: SchoolZoneEnum | null, classNivel: string[]) => void;
  setProgrammation: (programmation: Programmation) => void;
  setProgrammationType: (programmationType: ProgrammationTypeEnum) => void;
  isInitialLoading: boolean;
};
const AuthContext = createContext<ContextData | undefined>(undefined);

const UserDataKey: [base: string] = ["user"];

export type UserData = {
  email: string;
  username: string;
  schoolZone: SchoolZoneEnum | null;
  classNivel: string[];
  userType: UserTypeEnum;
  isAdmin: boolean;
  isAdherent: boolean;
  isVip: boolean;
  programmation: Programmation;
  programmationType: ProgrammationTypeEnum;
};

const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const queryClient = useQueryClient();

  const [user, setUser] = useState<UserData | null | undefined>(null);

  const { data: _user, isLoading } = useQuery<UserData>({ queryKey: UserDataKey, queryFn: api.user.getUserData, refetchOnMount: false, refetchOnReconnect: false });

  useEffect(() => {
    if (!isLoading) {
      setUser(_user);
    }
  }, [_user, isLoading]);

  // call this function to logged out in user
  const logout = useCallback(() => {
    api.user
      .logout()
      .then(() => {
        window.location.replace(Routes.APPS);
        queryClient.removeQueries();
        setUser(undefined);
      })
      .catch(() => {
        toast.error("Erreur lors de la déconnexion, veuillez contacter un administrateur.", { toastId: "logout" });
      });
  }, [queryClient]);

  const setRituelParameters = (schoolZone: SchoolZoneEnum | null, classNivel: string[]) => {
    setUser((old) => (old ? { ...old, schoolZone: schoolZone, classNivel: classNivel } : undefined));
  };

  const setProgrammation = (programmation: Programmation) => {
    setUser((old) => (old ? { ...old, programmation: programmation } : undefined));
  };

  const setProgrammationType = (programmationType: ProgrammationTypeEnum) => {
    setUser((old) => (old ? { ...old, programmationType: programmationType } : undefined));
  };

  const value = useMemo(
    () => ({ user: user && Object.keys(user).length === 0 ? undefined : user, logout, setRituelParameters, setProgrammation, setProgrammationType, isInitialLoading: user === null }),
    [logout, user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  const object = useContext(AuthContext);
  if (!object) {
    throw new Error("useAuth must be used within a Provider");
  }
  return object;
};

export { AuthProvider, useAuth };
