enum ProgrammationTypeEnum {
  MathsEnVie = "mathsenvie",
  Tandem = "tandem",
  Custom = "custom",
}

class ProgrammationTypeEnumUtil {
  public static toString(programmationType: ProgrammationTypeEnum) {
    switch (programmationType) {
      case ProgrammationTypeEnum.MathsEnVie:
        return "M@ths en-vie";
      case ProgrammationTypeEnum.Tandem:
        return "Tandem";
      case ProgrammationTypeEnum.Custom:
        return "Personnalisée";
    }
  }
}

export { ProgrammationTypeEnum, ProgrammationTypeEnumUtil };
