import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MutationData, Student } from "../types";
import { StudentsKey } from "./query";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GameTypeEnum, ModalTypeEnum, SchoolNivelEnum, studentIcones, UserTypeEnum } from "../utils";
import { FormButtonLoading } from "../components";
import { LinearProgress } from "@mui/material";
import { api, ApiError } from "../hooks";
import { faChild, faClockRotateLeft, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { useModals } from "../contexts/modals";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Routes } from "../routes";

type FormValues = {
  nivel: SchoolNivelEnum | "";
  ranks: number[];
};

const defaultValues: FormValues = {
  nivel: "",
  ranks: [],
};

function TeacherDashboard() {
  const queryClient = useQueryClient();
  const { openModal, onModalClose } = useModals();

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { canAccess: boolean } | null;

  useEffect(() => {
    if (!state?.canAccess) {
      openModal(ModalTypeEnum.SudoMode, { userType: UserTypeEnum.Teacher });
      onModalClose(ModalTypeEnum.SudoMode, () => navigate(Routes.AT_SCHOOL));
    }
  }, [state, openModal, onModalClose, navigate]);

  const { register, handleSubmit, reset, setValue, getValues, watch } = useForm({ defaultValues: defaultValues });

  const addRank = (rank: number) => {
    setValue("ranks", [...getValues("ranks"), rank]);
  };

  const removeRank = (rankToRemove: number) => {
    setValue("ranks", [...getValues("ranks").filter((rank: number) => rank !== rankToRemove)]);
  };

  const {
    data: students,
    isSuccess,
    isLoading,
  } = useQuery<Student[]>({
    queryKey: StudentsKey,
    queryFn: api.student.getStudents,
  });

  const addStudents = useMutation<MutationData, ApiError<FormValues>, FormValues>({
    mutationFn: (data) => {
      return api.student.addStudents(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: StudentsKey });
      reset();
    },
  });

  const onSubmit = (data: FormValues) => {
    addStudents.mutate(data);
  };

  const getColorGame = (student: Student, gameType: GameTypeEnum, period: string, index: number = 0): string => {
    const games = student.games;

    let currentIndex = 0;
    for (const game of games) {
      if ((period !== game.period && (parseInt(period) + 0.5).toString() !== game.period) || gameType !== game.type) {
        continue;
      }

      if (currentIndex === index) {
        if (game.type === GameTypeEnum.Evaluation) {
          switch (game.evaluationLevel) {
            case 1:
              return "red";
            case 2:
              return "orange";
            case 3:
              return "green";
            default:
              break;
          }
        } else if (game.type === GameTypeEnum.Training) {
          switch (game.trainingLevel) {
            case 1:
              return "red";
            case 2:
              return "orange";
            case 3:
              return "green";
            default:
              break;
          }
        }
      }
      currentIndex++;
    }

    return gameType === GameTypeEnum.Evaluation ? "gray" : "white";
  };

  if (!state?.canAccess) {
    return <></>;
  }

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-wrap gap-2 justify-content-around mb-3">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="d-flex text-center">
            <div className="align-self-center">
              <span className="fw-bold">1 :</span> Sélectionner les élèves
            </div>
            <span className="ms-3 me-2 align-self-center fw-bold">2 :</span>
            <div>
              <select required={true} className="form-select" {...register("nivel")}>
                <option hidden value="">
                  Choisir un niveau
                </option>
                <option value="cp">CP</option>
                <option value="ce1">CE1</option>
                <option value="ce2">CE2</option>
                <option value="cm1">CM1</option>
                <option value="cm2">CM2</option>
              </select>
            </div>
            <span className="ms-3 me-2 align-self-center fw-bold">3 :</span>
            <FormButtonLoading isPending={addStudents.isPending} label="Configurer" className="btn btn-primary float-end" disabled={watch("nivel") === "" || watch("ranks").length === 0} />
          </div>
        </form>
        <div className="align-self-center">
          <a className="btn btn-primary" href="pdfs/ComptesElèves.pdf" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faFilePdf} /> Fiche comptes élèves
          </a>
        </div>
        <div className="align-self-center">
          <button className="btn btn-danger" onClick={() => openModal(ModalTypeEnum.YearReset)}>
            <FontAwesomeIcon icon={faClockRotateLeft} /> Changement d'année
          </button>
        </div>
      </div>
      {isLoading && <LinearProgress className="m-auto" style={{ maxWidth: 960 }} />}
      {isSuccess && (
        <div className="d-flex flex-column gap-4 mx-auto">
          {/* Students */}
          {students.map((student) => (
            <div key={student.rank} className="d-flex">
              <input
                className="form-check-input align-self-center mt-0 border-2"
                type="checkbox"
                checked={watch("ranks").includes(student.rank)}
                onChange={(e) => {
                  if (e.target.checked) {
                    addRank(student.rank);
                  } else {
                    removeRank(student.rank);
                  }
                }}
                disabled={!student.canModifyNivel}
              />
              <FontAwesomeIcon
                className={`mx-2 align-self-center ${student.isEnabled ? (student.rank === 31 ? "text-danger" : "text-secondary") : "text-ternary"}`}
                icon={studentIcones[student.rank]}
                width="30"
                size="2x"
              />
              <p className="align-self-center">Niveau&nbsp;:</p>
              <span className="fw-bold text-uppercase align-self-center text-start ps-1" style={{ width: 40, minWidth: 40 }}>
                {student.nivel}
              </span>
              <div className="d-flex flex-wrap ms-1 column-gap-2 row-gap-1">
                {Array.from([1, 2, 3, 4, 5]).map((x) => (
                  <div
                    key={x}
                    className="d-flex border border-black border-2 px-2"
                    style={{ borderTopLeftRadius: x === 1 ? 10 : 0, borderBottomLeftRadius: x === 1 ? 10 : 0, borderTopRightRadius: x === 5 ? 10 : 0, borderBottomRightRadius: x === 5 ? 10 : 0 }}
                  >
                    <div className="align-self-center fw-bold text-center" style={{ width: 40 }}>
                      Eval
                    </div>
                    <div
                      key={"evalutation" + x}
                      className="border-2 border-black border align-self-center"
                      style={{ aspectRatio: 1, width: 16, backgroundColor: getColorGame(student, GameTypeEnum.Evaluation, x.toString()) }}
                    ></div>
                    <div className="align-self-center fw-bold text-center" style={{ width: 26 }}>
                      P{x}
                    </div>
                    <div className="d-flex" style={{ columnGap: 1 }}>
                      {Array.from([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]).map((y) => (
                        <div
                          key={"training" + x + "e" + y}
                          className="border-1 border-black border align-self-center"
                          style={{ aspectRatio: 1, width: 16, backgroundColor: getColorGame(student, GameTypeEnum.Training, x.toString(), y) }}
                        ></div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              {student.helpRequests.length > 0 && (
                <button className="btn py-0 px-2 position-relative me-2" onClick={() => openModal(ModalTypeEnum.HelpRequests, { _student: student })}>
                  <FontAwesomeIcon icon={faChild} style={{ fontSize: 25 }} />
                  <span className="badge text-bg-danger position-absolute" style={{ right: -10, top: -6 }}>
                    {student.helpRequests.length}
                  </span>
                </button>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default TeacherDashboard;
