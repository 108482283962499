import axios from "axios";
import { getPostPutAxiosInstance, handleError } from "./utils";

class ProfilAPI {
  async updateRituelParameters(data: any) {
    try {
      const response = await getPostPutAxiosInstance().put("/profil/update-rituel-parameters", data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async updateProgrammationType(data: any) {
    try {
      const response = await getPostPutAxiosInstance().put("/profil/update-programmation-type", data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }
}

export default ProfilAPI;
