import { ButtonLink } from "../components";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { api } from "../hooks";
import { StatsData, StatsKey } from "./query";
import { useQuery } from "@tanstack/react-query";

function Generic() {
  const [step, setStep] = useState<"logo" | "bandeau" | "button" | null>("logo");

  const { data: statsData } = useQuery<StatsData>({ queryKey: StatsKey, queryFn: api.atelier.stats, refetchOnReconnect: false });

  useEffect(() => {
    setTimeout(() => {
      setStep((old) => (old === "button" ? "button" : null));
      setTimeout(() => {
        setStep((old) => (old === "button" ? "button" : "bandeau"));
        setTimeout(() => {
          setStep("button");
        }, 1000);
      }, 500);
    }, 1000);
  }, []);

  return (
    <div id="backgroundImage" className="position-relative h-100" style={{ backgroundImage: "url(img/essai3.webp)" }}>
      <div className="d-flex flex-column h-100 justify-content-center p-3" onClick={() => setStep("button")}>
        {(step === "logo" || step === null) && (
          <div className="text-center w-100 mb-5" style={{ maxHeight: "70%", opacity: step === "logo" ? "100%" : "0%", transition: "opacity 0.5s ease-in" }}>
            <img src="img/logoAtelier.webp" className="mw-100 mh-100" alt="Logo Atelier" />
          </div>
        )}
        {(step === "bandeau" || step === "button") && (
          <>
            <div className="text-center w-100 align-self-center" style={{ maxHeight: step === "button" ? "50%" : "70%", transition: "max-height 2s", marginBottom: "8vh" }}>
              <img src="img/bandeauAtelier.webp" className="mw-100 mh-100" alt="Bandeau Atelier" />
            </div>
            <div className="mx-auto" style={{ width: "35%", opacity: step === "button" ? "100%" : "0%" }}>
              <ButtonLink icone={faPlay} path="HOME" label="Commencer" fontSize={4} height={step === "button" ? "8vh" : "0vh"} transition />
            </div>

            <div
              className="text-center fs-2 d-flex column-gap-4 flex-wrap justify-content-center mt-4 fw-bold text-primary bg-white mx-auto rounded-3 p-1 px-4 placeholder-glow"
              style={{ transition: "opacity 0.5s ease-in", opacity: step === "button" ? "100%" : "0%" }}
            >
              <div>
                {statsData ? (
                  <span className="text-secondary mx-2">{statsData.classesCount}</span>
                ) : (
                  <span className="placeholder bg-secondary mx-2 rounded-3 align-self-center" style={{ width: 50, height: "50%" }}></span>
                )}
                classes{" "}
              </div>
              <div>
                {statsData ? (
                  <span className="text-secondary mx-2">{statsData.studentsCount}</span>
                ) : (
                  <span className="placeholder bg-secondary mx-2 rounded-3 align-self-center" style={{ width: 60, height: "50%" }}></span>
                )}
                élèves
              </div>
              <div>
                {statsData ? (
                  <span className="text-secondary mx-2">{statsData.problemsResolved}</span>
                ) : (
                  <span className="placeholder bg-secondary mx-2 rounded-3 align-self-center" style={{ width: 80, height: "50%" }}></span>
                )}
                problèmes résolus
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Generic;
