import { useForm } from "react-hook-form";
import { Checkbox } from ".";
import { api, ApiError } from "../hooks";
import { useMutation } from "@tanstack/react-query";
import { MutationData, Programmation } from "../types";
import { Alert } from "react-bootstrap";
import { useAuth } from "../contexts/auth";
import { ProgrammationTypeEnum, ProgrammationTypeEnumUtil } from "../utils";
import { useEffect } from "react";

type FormValues = {
  programmationType: ProgrammationTypeEnum;
};

function ProgrammationTypeForm() {
  const { user, setProgrammation, setProgrammationType } = useAuth();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: async () => await defaultValues,
    mode: "onChange",
  });

  const defaultValues = new Promise<FormValues>((resolve) => {
    if (user) {
      resolve({
        programmationType: user.programmationType,
      });
    } else {
      resolve({
        programmationType: ProgrammationTypeEnum.MathsEnVie,
      });
    }
  });

  useEffect(() => {
    if (user) {
      setValue("programmationType", user.programmationType);
    }
  }, [setValue, user]);

  const programmationTypeMutation = useMutation<MutationData & { programmation: Programmation; programmationType: ProgrammationTypeEnum }, ApiError, FormValues>({
    mutationFn: (data) => {
      return api.profil.updateProgrammationType(data);
    },
    onSuccess: (data) => {
      setProgrammation(data.programmation);
      setProgrammationType(data.programmationType);
      reset({ programmationType: data.programmationType });
    },
    onError: () => {
      reset();
    },
  });

  const onSubmit = (data: FormValues) => {
    programmationTypeMutation.mutate(data);
  };

  return (
    <form noValidate>
      {programmationTypeMutation.isError && <Alert variant={programmationTypeMutation.error.variant}>{programmationTypeMutation.error.message}</Alert>}

      <div className="d-flex flex-wrap justify-content-center">
        <Checkbox
          label={ProgrammationTypeEnumUtil.toString(ProgrammationTypeEnum.MathsEnVie)}
          name="programmationType"
          value={ProgrammationTypeEnum.MathsEnVie}
          register={register}
          error={errors.programmationType}
          onChange={handleSubmit(onSubmit)}
          disabled={programmationTypeMutation.isPending}
        />
        <Checkbox
          label={ProgrammationTypeEnumUtil.toString(ProgrammationTypeEnum.Tandem)}
          name="programmationType"
          value={ProgrammationTypeEnum.Tandem}
          register={register}
          error={errors.programmationType}
          onChange={handleSubmit(onSubmit)}
          disabled={programmationTypeMutation.isPending}
        />
        <Checkbox
          label={ProgrammationTypeEnumUtil.toString(ProgrammationTypeEnum.Custom)}
          name="programmationType"
          value={ProgrammationTypeEnum.Custom}
          register={register}
          error={errors.programmationType}
          onChange={handleSubmit(onSubmit)}
          disabled={programmationTypeMutation.isPending}
        />
      </div>
    </form>
  );
}

export default ProgrammationTypeForm;
