import { api, ApiError, useBlocker, useUtils } from "../hooks";
import { Button, KeypadDisplay, NumericKeypad, ProgressBar, SpeechButton } from "../components";
import { GameData, Problem } from "../types";
import { useEffect, useState } from "react";
import { AidTypeEnum, GameTypeEnum, ModalTypeEnum, ProblemStateEnum, aidUsed, canUseAid, studentIcones } from "../utils";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { Routes } from "../routes";
import { faCheck, faComment, faForward, faPersonChalkboard, faPlay, faSuitcaseMedical, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { useModals } from "../contexts/modals";
import { useMutation } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Game() {
  const { addBoldToQuestion, br, sanitize } = useUtils();
  const { openModal, closeModal } = useModals();

  const navigate = useNavigate();
  const location = useLocation();

  const [game, setGame] = useState<GameData>(useLoaderData() as GameData);
  const [blockNavigation, setBlockNavigation] = useState(true);
  const [actualProblem, setActualProblem] = useState<Problem>(game.problems[0]);
  const [response, setResponse] = useState("");
  const [textZone, setTextZone] = useState("");
  const [canAnswer, setCanAnswer] = useState(false);
  const [answerStatus, setAnswerStatus] = useState<ProblemStateEnum.ANSWERED_CORRECTLY | ProblemStateEnum.ANSWERED_WRONGLY | null>(null);
  const [showPrePage, setShowPrePage] = useState(game.prePageLabel !== null);

  useBlocker({ title: "Quitter la partie ?", message: "Êtes-vous sûr de vouloir quitter la partie.", when: blockNavigation });

  const skipProblemAid = () => {
    if (canUseAid(game, AidTypeEnum.SkipProblem)) {
      aidUsed(game, setGame, AidTypeEnum.SkipProblem);

      displayNextProblem(ProblemStateEnum.SKIPPED);
    }
  };

  const displayHelpNivel1Aid = () => {
    if (canUseAid(game, AidTypeEnum.AidNivel1)) {
      aidUsed(game, setGame, AidTypeEnum.AidNivel1);

      setTimeout(() => {
        setTextZone(actualProblem.helpSentence1);
      }, 2000);
    }
  };

  const displayHelpNivel2Aid = () => {
    if (canUseAid(game, AidTypeEnum.AidNivel2)) {
      aidUsed(game, setGame, AidTypeEnum.AidNivel2);

      setTimeout(() => {
        setTextZone(actualProblem.helpSentence2);
      }, 2000);
    }
  };

  const respond = () => {
    setTextZone("");
    setCanAnswer(true);
  };

  const checkAnswer = () => {
    const problemResponse = actualProblem.response;

    let response1f: number;
    let response2f: number;
    if (response.includes("/")) {
      const split = response.split("/");
      response1f = parseInt(split[0]) / parseInt(split[1]);
    } else {
      response1f = parseFloat(response.replaceAll(",", "."));
    }
    if (problemResponse.includes("/")) {
      const split = problemResponse.split("/");
      response2f = parseInt(split[0]) / parseInt(split[1]);
    } else {
      response2f = parseFloat(problemResponse.replaceAll(",", "."));
    }

    const response1s = response1f.toFixed(2);
    const response2s = response2f.toFixed(2);

    const answerIsCorrect = response1s === response2s;

    if (game.type === GameTypeEnum.Evaluation) {
      displayNextProblem(answerIsCorrect ? ProblemStateEnum.ANSWERED_CORRECTLY : ProblemStateEnum.ANSWERED_WRONGLY);
    } else if (answerIsCorrect) {
      setAnswerStatus(ProblemStateEnum.ANSWERED_CORRECTLY);
      openModal(ModalTypeEnum.Answer, { isCorrectAnswer: true, answerGiven: response });
      setTimeout(
        () => {
          closeModal(ModalTypeEnum.Answer);
          displayNextProblem(ProblemStateEnum.ANSWERED_CORRECTLY);
        },
        process.env.NODE_ENV === "development" ? 200 : 2500
      );
    } else {
      setAnswerStatus(ProblemStateEnum.ANSWERED_WRONGLY);
      openModal(ModalTypeEnum.Answer, { isCorrectAnswer: false, answerGiven: response });
      setTimeout(
        () => {
          closeModal(ModalTypeEnum.Answer);
        },
        process.env.NODE_ENV === "development" ? 200 : 2500
      );
    }

    setCanAnswer(false);
  };

  const displayNextProblem = (newState: ProblemStateEnum) => {
    setGame((old) => ({
      ...old,
      problems: [
        ...old.problems.map((problem, i) => {
          if (i === old.actualProblem) {
            return {
              ...problem,
              state: newState,
              responseGiven: response,
            };
          } else {
            return problem;
          }
        }),
      ],
      actualProblem: Math.min(game.problems.length - 1, old.actualProblem + 1),
    }));

    setTextZone("");
    setResponse("");
    setAnswerStatus(null);
  };

  useEffect(() => {
    const problemsSkipped = game.problems.filter((problem) => problem.state === ProblemStateEnum.SKIPPED).length;
    if (
      game.problems.filter((problem) => problem.state === ProblemStateEnum.ANSWERED_CORRECTLY || problem.state === ProblemStateEnum.ANSWERED_WRONGLY).length ===
      Math.min(game.problems.length - problemsSkipped, game.nbProblems)
    ) {
      setBlockNavigation(false);
      navigate({ pathname: Routes.END_GAME, search: location.search }, { replace: true, state: game });

      return;
    }

    setActualProblem(game.problems[game.actualProblem]);
  }, [game, navigate, location]);

  const addHelpRequest = useMutation<void, ApiError, any>({
    mutationFn: (data) => {
      return api.helpRequest.add(data);
    },
    onSuccess: () => {
      displayNextProblem(ProblemStateEnum.ANSWERED_WRONGLY);
    },
  });

  if (showPrePage && game.prePageLabel) {
    return (
      <div id="backgroundImage" className="position-relative h-100" style={{ backgroundImage: "url(img/essai3.webp)" }}>
        <div className="position-relative d-flex flex-column justify-content-around h-100 m-auto" style={{ width: "70%" }}>
          <Button icone={faPlay} label={game.prePageLabel} fontSize={8.5} height="20vh" onClick={() => setShowPrePage(false)} />
        </div>
      </div>
    );
  }

  return (
    <div className="h-100 d-flex flex-row">
      <div className="h-100 d-flex flex-column align-items-center justify-content-between" style={{ width: "95%" }}>
        {/* Statement */}
        <div
          className="w-100 d-flex justify-content-evenly flex-column"
          style={{
            height: "50%",
            backgroundImage: `url(${answerStatus === ProblemStateEnum.ANSWERED_WRONGLY || game.type === GameTypeEnum.Evaluation ? "img/secondaryWave.webp" : "img/primaryWave.webp"})`,
            backgroundPositionX: "left",
            backgroundPositionY: "bottom",
            backgroundRepeat: "repeat-x",
            backgroundSize: "100% 60%",
          }}
        >
          <div className="mx-auto" style={{ flex: "1 1 0" }}></div>
          <div
            key={actualProblem.title}
            className="position-relative mx-auto mh-100 bg-white rounded-4 p-2 text-break overflow-auto border-3 text-primary border border-primary"
            style={{ animation: "spin 1s ease-in-out", height: "fit-content", width: "75%" }}
          >
            <div style={{ marginRight: 24 }}>
              <span style={{ fontSize: "calc(1.2rem + 1.5vh)" }} dangerouslySetInnerHTML={sanitize(addBoldToQuestion(br(actualProblem.statement)))}></span>
            </div>
            <SpeechButton text={actualProblem.statement} />
          </div>
          {/* </div> */}
          <div className="mx-auto d-flex" style={{ fontSize: "calc(1.3rem + 1.5vh)", flex: "1 1 0" }}>
            {(canAnswer || answerStatus !== null) && (
              <div className="text-white fw-bold align-self-center text-center position-relative">
                {answerStatus === ProblemStateEnum.ANSWERED_WRONGLY && (
                  <>
                    <span>Tu as répondu : </span>
                    <span className="mx-1 d-inline-block bg-danger rounded-3 px-2">
                      <KeypadDisplay response={response} />
                    </span>
                    <span className="me-5">.</span>
                  </>
                )}
                <span>{actualProblem.responseSentence.split("[]", 2)[0]}</span>
                {/* Wrong or correct response display */}
                {answerStatus === ProblemStateEnum.ANSWERED_WRONGLY ? (
                  <span className="mx-2 d-inline-block bg-success rounded-3 px-2">
                    <KeypadDisplay response={actualProblem.response} />
                  </span>
                ) : (
                  <span className="mx-2 d-inline-block bg-success rounded-3 px-2">
                    <KeypadDisplay response={response} />
                  </span>
                )}

                <span>{actualProblem.responseSentence.split("[]", 2)[1]}</span>
              </div>
            )}
          </div>
        </div>

        {/* Buttons */}
        {!canAnswer && answerStatus === null && (
          <div className="d-flex flex-column justify-content-evenly" style={{ width: "80%", height: "50%" }}>
            <div className="w-100" style={{ marginBottom: "1vh", marginTop: "1vh" }}>
              <Button icone={faComment} height="10vh" fontSize={8} iconeBgColor="green" label="Répondre" onClick={respond} />
              {game.type !== GameTypeEnum.Evaluation && (
                <div className="d-flex w-100" style={{ marginTop: "2vh", columnGap: "1vw" }}>
                  <Button icone={faThumbsUp} height="8vh" fontSize={2.2} disabled={!canUseAid(game, AidTypeEnum.AidNivel1)} label="Coup de pouce" onClick={displayHelpNivel1Aid} />
                  {!["cp", "ce1"].includes(actualProblem.nivel) && (
                    <Button icone={faSuitcaseMedical} height="8vh" fontSize={2.2} disabled={!canUseAid(game, AidTypeEnum.AidNivel2)} label="Au secours !" onClick={displayHelpNivel2Aid} />
                  )}
                  <Button icone={faForward} height="8vh" fontSize={2.2} disabled={!canUseAid(game, AidTypeEnum.SkipProblem)} label="Passer" onClick={skipProblemAid} />
                </div>
              )}
            </div>
            {textZone && (
              <div
                className="p-2 position-relative mx-auto w-75 overflow-auto fw-bold bg-secondary rounded-3 border-3 border-secondary border text-white"
                style={{ fontSize: "calc(0.7rem + 1.5vh)", marginBottom: "1vh" }}
              >
                <div style={{ marginRight: 24 }}>{textZone}</div>
                <SpeechButton text={textZone} bgColor="primary" />
              </div>
            )}
          </div>
        )}

        {answerStatus === ProblemStateEnum.ANSWERED_WRONGLY && (
          <div className="w-75 mx-auto d-flex flex-column justify-content-around" style={{ height: "50%" }}>
            <div
              className="p-2 overflow-auto fw-bold w-100 bg-white rounded-3 border-3 border-success border text-success position-relative"
              style={{ fontSize: "calc(0.7rem + 1.5vh)", height: "fit-content", maxHeight: "60%" }}
            >
              <div style={{ marginRight: 24 }}>{actualProblem.responseExplication}</div>
              <SpeechButton text={actualProblem.responseExplication} />
            </div>
            <div className="d-flex w-100 mx-auto" style={{ columnGap: "3vh" }}>
              {game.type === GameTypeEnum.Training && (
                <Button
                  icone={faPersonChalkboard}
                  height="10vh"
                  fontSize={2.5}
                  label="Je demande à un adulte"
                  onClick={() => addHelpRequest.mutate({ responseGiven: response, rank: game.student.rank, problemId: actualProblem.id })}
                  isPending={addHelpRequest.isPending}
                />
              )}
              <Button icone={faCheck} height="10vh" iconeBgColor="green" fontSize={2.5} label="J'ai compris mon erreur" onClick={() => displayNextProblem(ProblemStateEnum.ANSWERED_WRONGLY)} />
            </div>
          </div>
        )}

        {canAnswer && answerStatus === null && (
          <div className="m-auto" style={{ height: "40%", overflow: "auto" }}>
            <NumericKeypad setResponse={setResponse} response={response} onValid={checkAnswer} />
          </div>
        )}
      </div>
      <div className="h-100 d-flex flex-column row-gap-4" style={{ width: "5%", padding: "2vh 0.5vw" }}>
        <div className="d-flex flex-column justify-content-around mx-auto" style={{ flex: "1 1 0" }}>
          {game.type !== GameTypeEnum.Evaluation && (
            <>
              {Array.from({ length: game.aidPointsUsed }, (_, i) => (
                <div key={i + "grey"} className="text-center" style={{ maxHeight: "33.3333%" }}>
                  <img className="mw-100 mh-100" style={{ filter: "grayscale(1)", opacity: "0.5", animation: "spin 2s ease-in-out" }} src="img/joker.webp" alt="Joker d'aide" />
                </div>
              ))}
              {Array.from({ length: game.aidPoints - game.aidPointsUsed }, (_, i) => (
                <div key={i} className="text-center" style={{ maxHeight: "33.3333%" }}>
                  <img className="mw-100 mh-100" src="img/joker.webp" alt="Joker d'aide" />
                </div>
              ))}
            </>
          )}
        </div>
        {game.type !== GameTypeEnum.Demo && (
          <div className="mx-auto text-center">
            <FontAwesomeIcon className="text-danger h-100 w-100" style={{ maxWidth: "50%" }} icon={studentIcones[game.student.rank]} />
          </div>
        )}
        <div className="d-flex mx-auto" style={{ width: "30%", flex: "1 1 0" }}>
          <ProgressBar game={game} />
        </div>
      </div>
    </div>
  );
}

export default Game;
